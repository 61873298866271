<template>
  <div class="image-slider" :class="{ large }">
    <TwicImg class="image-product" :src="currentImage" alt="Suggestion" ratio="none" />
    <span
      v-if="images.length > 1"
      class="arrow left"
      aria-label="slide-left"
      @click.prevent="currentIndex = currentIndex > 0 ? currentIndex - 1 : images.length - 1"
    >
      <Icon class="icon" name="left" :height="iconSize" stroke="var(--color-black)" />
    </span>
    <span
      v-if="images.length > 1"
      class="arrow right"
      aria-label="slide-right"
      @click.prevent="currentIndex = currentIndex === images.length - 1 ? 0 : (currentIndex += 1)"
    >
      <Icon class="icon" name="right" :height="iconSize" stroke="var(--color-black)" />
    </span>
    <div class="dotscroll-container flex">
      <Icon
        v-for="(i, index) in images"
        :key="index"
        :fill="index === currentIndex ? 'var(--color-green)' : '#c0c0c0'"
        name="dotscroll"
        class="m-right"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    images: string[];
    large?: boolean;
  }>(),
  { large: false }
);

const currentImage = ref(props.images[0]);
const currentIndex = ref(0);

const iconSize = computed(() => (props.large ? '12px' : '8px'));

watchEffect(() => (currentImage.value = props.images[currentIndex.value]));
</script>

<style lang="scss" scoped>
@use '$/animation.scss';
@use '$/border-radius.scss';
@use '$/mouse-behavior.scss';
@use '$/shadows.scss';

.image-slider {
  position: absolute;

  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 100%;

  &.large .arrow {
    width: 32px;
    height: 32px;
  }

  .image-product {
    $border-radius: border-radius.$small;

    width: 100%;
    height: 100%;

    object-fit: cover;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  .arrow {
    $spacing: 18px;

    @include mouse-behavior.clickable-opacity();

    position: absolute;
    z-index: 3;
    transform: translateZ(0);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;

    background-color: white;
    border-radius: 100%;
    box-shadow: shadows.$block;

    &.right {
      right: $spacing;
    }

    &.left {
      left: $spacing;
    }

    .icon:deep(path) {
      stroke-width: 1;
    }
  }

  .dotscroll-container {
    position: absolute;
    bottom: -15px;
    justify-content: center;
    width: 100%;

    .m-right {
      margin-right: 12px;
    }
  }
}

@media (width <= 768px) {
  .image-slider {
    .arrow {
      width: 20px;
      height: 20px;
    }

    &.large {
      .arrow {
        width: 20px;
        height: 20px;
      }
    }
  }
}
</style>
